<template>
  <div class="w-full">
    <div class="mt-1 flex w-full animate-pulse items-center px-4 py-1">
      <div>
        <div class="mb-2 ml-4 h-2 w-10 rounded bg-gray-800"></div>
        <div class="h-2 w-14 rounded bg-gray-800"></div>
      </div>
      <div class="flex-1 space-y-2 py-1 px-6">
        <div>
          <div class="h-2 w-1/12 rounded bg-gray-800"></div>
        </div>
        <div class="h-2 w-3/4 rounded bg-gray-800"></div>
        <div class="flex">
          <div class="h-2 w-2/12 rounded bg-gray-800"></div>
          <div class="ml-2 h-2 w-1/12 rounded bg-gray-800"></div>
          <div class="ml-2 h-2 w-1/12 rounded bg-gray-800"></div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'NewsFeedTemplateCard'
};
</script>
